import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAllDepartments(ctx, queryParams) {
      let radioId=queryParams.radioId;

      return new Promise((resolve, reject) => {
        axios
          .get('admin/radio/wishlist/?radioId='+radioId,{ params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deletewish(ctx, queryParams) {
      let radioId=queryParams.id;

      return new Promise((resolve, reject) => {
        axios
          .delete('admin/radio/wishlist/'+radioId, )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
     
    createDepartment(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('admin/cms/blogtag',  userData  )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateblogtag(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .put('admin/cms/updateblogtag',  userData  )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    


  },
}
